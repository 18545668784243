exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-work-atelico-index-js": () => import("./../../../src/pages/work/atelico/index.js" /* webpackChunkName: "component---src-pages-work-atelico-index-js" */),
  "component---src-pages-work-doko-index-js": () => import("./../../../src/pages/work/doko/index.js" /* webpackChunkName: "component---src-pages-work-doko-index-js" */),
  "component---src-pages-work-funke-index-js": () => import("./../../../src/pages/work/funke/index.js" /* webpackChunkName: "component---src-pages-work-funke-index-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-isaura-vieyra-index-js": () => import("./../../../src/pages/work/isaura-vieyra/index.js" /* webpackChunkName: "component---src-pages-work-isaura-vieyra-index-js" */),
  "component---src-pages-work-joli-index-js": () => import("./../../../src/pages/work/joli/index.js" /* webpackChunkName: "component---src-pages-work-joli-index-js" */),
  "component---src-pages-work-lala-index-js": () => import("./../../../src/pages/work/lala/index.js" /* webpackChunkName: "component---src-pages-work-lala-index-js" */),
  "component---src-pages-work-mtn-index-js": () => import("./../../../src/pages/work/mtn/index.js" /* webpackChunkName: "component---src-pages-work-mtn-index-js" */),
  "component---src-pages-work-party-pack-index-js": () => import("./../../../src/pages/work/party-pack/index.js" /* webpackChunkName: "component---src-pages-work-party-pack-index-js" */),
  "component---src-pages-work-revenge-body-by-l-index-js": () => import("./../../../src/pages/work/revenge-body-by-l/index.js" /* webpackChunkName: "component---src-pages-work-revenge-body-by-l-index-js" */),
  "component---src-pages-work-wd-index-js": () => import("./../../../src/pages/work/wd/index.js" /* webpackChunkName: "component---src-pages-work-wd-index-js" */)
}

