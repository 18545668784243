import React from "react";

const defaultState = {
  cookiesNoticed: false,
  toggleCookies: () => {},
};

const CookiesContext = React.createContext(defaultState);

const CookiesProvider = (props) => {
  const [cookiesNoticed, setCookiesNoticed] = React.useState(
    defaultState.cookiesNoticed
  );

  const toggleCookies = () => setCookiesNoticed(true);

  const { children } = props;

  return (
    <CookiesContext.Provider
      value={{
        cookiesNoticed,
        toggleCookies,
      }}
    >
      { children }
    </CookiesContext.Provider>
  );
};

export default CookiesContext;

export { CookiesProvider };
